
import moment from 'moment'
import axios from '@/services/axios'
import { formatterDollars } from '@/utils/formatter'
import { defineComponent, ref, computed, reactive, watch } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useFetchData, useNotification } from '@/composables'
import Pagination from '@/components/common/Pagination.vue'
import DepositDialog from './DepositDialog.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import FilterTable from '@/components/common/FilterTable.vue'
import { useResource } from '@/services'

export default defineComponent({
  components: {
    Pagination,
    sectionLayoutContent,
    DepositDialog,
    FilterTable,
  },
  setup() {
    const breadcrumbs = ['Balance', '']
    const depositParams = reactive({
      page: 1,
      limit: 10,
      payWith: '',
      status: '',
      isCashback: null,
      note: '',
    })
    const depositData = useFetchData('deposits/seller-teams', depositParams)
    const seller = ref({})
    const balance = ref('0')
    const info = JSON.parse(localStorage.getItem('info') || '')

    const isLoading = ref(false)

    const content = computed(() => {
      return { breadcrumbs }
    })

    const onFilterCallback = (filter: any) => {
      let search = filter?.search
      depositParams.note = search
    }

    const columns = [
      {
        key: 'id',
        title: 'Created',
        dataIndex: 'createdAt',
        minWidth: 10,
      },
      {
        title: 'Pay with',
        dataIndex: 'payWith',
        minWidth: 10,
      },
      {
        title: 'Transaction ID',
        dataIndex: 'transactionId',
        minWidth: 10,
      },
      {
        title: 'Type',
        dataIndex: 'isCashback',
        minWidth: 10,
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        minWidth: 8,
      },
      {
        title: 'Balance',
        dataIndex: 'balance',
        align: 'center',
        minWidth: 8,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'center',
        minWidth: 8,
      },
      {
        title: 'Note',
        dataIndex: 'note',
        minWidth: 20,
      },
      {
        title: 'Action',
        dataIndex: 'action',
        align: 'center',
        minWidth: 15,
      },
    ]
    const loadBalance = async () => {
      try {
        let res = await axios.get('/balances/seller-teams')
        if (res.data) {
          balance.value = res.data.amount || '0'
        }
      } catch (err) {
        balance.value = '0'
      }
    }

    const getSeller = async () => {
      const resourceSellerTeam = '/seller-teams'
      const { get } = useResource(resourceSellerTeam)
      const { data, status } = await get(info.sellerTeam.id)
      if (status === 200) {
        seller.value = data as any
      }
    }

    const loadData = async () => {
      isLoading.value = true
      await loadBalance()
      await getSeller()
      await depositData.fetchData()
      isLoading.value = false
    }

    loadData()

    watch(depositParams, loadData)

    const changeBalance = async () => {
      loadData()
    }

    const { error, success } = useNotification()
    const deleteDeposits = async (row: any) => {
      if (!row?.id || row.status !== 'pending') {
        error('Deposits is completed. You cannot Edit or Delete this')
        return
      }
      const resource = `deposits/${row.id}`
      const res = (await axios.delete(resource)) as any
      if (res.status === 201 || res.status === 200) {
        success('Update success')
        loadData()
      } else {
        error(res.data.message)
      }
    }

    const onDelete = async (row: any) => {
      ElMessageBox.confirm('Confirm delete this deposits?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })
        .then(() => {
          deleteDeposits(row)
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: 'Delete canceled',
          })
        })
    }

    const depositDialog = ref<InstanceType<typeof DepositDialog>>()
    const openDepositDialog = async (actionType: string, row?: any) => {
      if (row) {
        depositDialog.value?.toggle(actionType, row)
      } else {
        depositDialog.value?.toggle(actionType)
      }
      if (!seller.value) {
        await getSeller()
      }
    }

    return {
      moment,
      content,
      columns,
      balance,
      changeBalance,
      formatterDollars,
      depositTotal: depositData.total,
      depositDocuments: depositData.documents,
      depositParams,
      isLoading,
      openDepositDialog,
      depositDialog,
      deleteDeposits,
      onDelete,
      onFilterCallback,
      getSeller,
      seller,
    }
  },
})
